import React from 'react';
import css from './ListingPage.module.css';
import { categoryFields } from '../../config/configListing';
import { Heading } from '../../components';
import { FormattedMessage } from 'react-intl';
const CategoryMaybe = props => {
  const { publicData } = props;
  if (!publicData) return null;
  const { category, subCategory } = publicData || {};
  const categories = categoryFields;
  const categoryLabel = categories?.find(c => c.key === category);
  const sectionCategory = category ? (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.CategoryMaybe.categoryLabel" />
      </Heading>
      <p className={css.detailLabel}>{categoryLabel?.label}</p>
    </div>
  ) : null;
  const sectionSubCategory = subCategory ? (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.CategoryMaybe.subCategoryLabel" />
      </Heading>
      <p>{categoryLabel?.subCategories?.find(c => c?.key === subCategory)?.label}</p>
    </div>
  ) : null;
  return (
    <div>
      {sectionCategory}
      {sectionSubCategory}
    </div>
  );
};

export default CategoryMaybe;
